// Definição das cores da paleta
$white: #f9f9f9; // Branco gelo
$black: #000000; // Preto sólido
$green-medium: #97c4a6; // Verde médio pastel
$green-light: #dfe9d5; // Verde claro pastel
$gray-dark: #5f5f5f; // Cinza escuro

// heros.scss

.hero-bottom-shape {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.hero-content {
  position: relative;
  z-index: 1;
}

.subscribe-form {
  position: relative;

  .form-control {
    background-color: rgba($white, .12);
    padding: 8px 180px 8px 50px;
    border-radius: 4px;
    min-height: 62px;
    color: $gray-dark;
    border-color: transparent;

    &:focus {
      box-shadow: 0 0 4px $green-light;
    }

    &::placeholder {
      color: $gray-dark;
      opacity: 0.5;
    }
  }

  .form-icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: $green-medium;
    opacity: 0.7;
    font-size: 20px;
  }

  .form-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    background-color: $green-medium;
    color: $white;

    &:hover {
      background-color: $green-light;
      color: $black;
    }

    .download-icon {
      display: none;
    }
  }
}

// hero-2
.hero-2 {
  padding: 140px 0 130px;
  background-color: $white;
  width: 100%;

  .hero-2-img {
    position: relative;

    &::after {
      content: "";
      background-color: $green-medium;
      position: absolute;
      bottom: -30px;
      left: -50px;
      height: 120px;
      width: 120px;
      border-radius: 50%;
      z-index: -1;
    }
  }
}

// hero-3
.hero-3 {
  padding: 170px 0 140px;
  background-color: $green-medium;
  width: 100%;
}

// hero-4
.hero-4 {
  padding: 170px 0 140px;
  background-color: $gray-dark;
  width: 100%;
}

// hero-5
.hero-5 {
  padding: 170px 0 450px;
  position: relative;
  background-color: $black;
  color: $white;
  width: 100%;

  &:hover {
    background-color: $green-medium;
  }
}

// hero-6
.hero-6 {
  position: relative;

  .carousel {
    .carousel-inner {
      .carousel-item {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $gray-dark;
        color: $white;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      .hero-slider-arrow {
        font-size: 30px;
        position: relative;
        color: $green-light;

        &::after {
          content: "";
          background-color: rgba($white, 0.12);
          border: 1px solid rgba($white, 0.2);
          height: 50px;
          width: 50px;
          position: absolute;
          transform: rotate(45deg);
          border-radius: 6px;
        }
      }

      &:hover {
        .hero-slider-arrow {
          color: $green-medium;

          &::after {
            background-color: $green-light;
            border-color: $green-medium;
          }
        }
      }
    }
  }
}

// responsive
@media (max-width: 991.98px) {
  .hero-2 {
    padding: 90px 0 40px;
  }

  .hero-3 {
    padding: 100px 0 20px;
  }

  .hero-4 {
    padding: 110px 0 20px;
  }
}

@media (max-width: 575.98px) {
  .subscribe-form {
    .form-control {
      padding: 8px 64px 8px 50px;
    }

    .form-btn {
      padding: 8px 14px;
      span {
        display: none;
      }
      .download-icon {
        display: block;
      }
    }
  }
}
